.inputWithSearchFilter {
	display: flex;
	align-items: center;
	margin-top: 10px;
}
.inputWithSearchFilter .searchButton {
	font-size: 10px;
	background-color: white;
	border: 1px solid #babfc7;
	border-left: none;
	color: #4cab45;
	padding: 2px 2px;
	border-radius: 0 3px 3px 0;
	cursor: pointer;
}

.customFloatingFilter {
	/* width: 80%;
	min-height: 24px;
	min-width: 10px;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	border-width: 1px;
	border-radius: 3px;
	border-style: solid;
	border-color: #babfc7;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%); */
	min-height: 24px;
	width: 100%;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	border-width: 1px;
	border-radius: 3px;
	border-style: solid;
	border-color: #babfc7;
}

.customFloatingFilter:focus {
	outline: none !important;
	box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
	border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}

.custom-filter {
	z-index: 0 !important;
}
