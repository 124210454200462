.login {
	height: 100vh;
	width: 100vw;
	background-image: url("../../../assets/login-background.jpeg");
	background-size: cover;
	background-position: center;
}

.login-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 5rem calc((100vw - 850px) / 2);
}

.login .logo-container img {
	width: 400px;
	height: 100px;
}

.login .theme-input-label {
	font-size: 1.3rem;
	font-weight: bold;
	font-family: inherit !important;
}
