.admin-pie-conatiner {
	display: flex;
	flex-wrap: wrap;
	border-radius: 15px;
	max-width: 40%;
	padding: 1.5rem;
	background-color: #f2f3fc;
}
.admin-container-body {
	display: flex;
	width: 100%;
	margin-top: 2rem;
	justify-content: space-between;
}
.admin-heading-title {
	width: 100%;
	text-align: left;
}
.admin-stat-data {
	margin-bottom: 12px;
	text-align: left;
}

.admin-stat-data p {
	font-weight: bold;
	font-size: 15px;
}

.admin-stat-data:nth-child(1) h2 {
	color: #8c4ccd;
}
.admin-stat-data:nth-child(2) h2 {
	color: #a06cd5;
}

.admin-heading-title p {
	font-size: 1rem;
	font-weight: bold;
}
.admin-heading-title h2 {
	font-weight: bolder;
	font-size: 1.3rem;
	margin-top: 8px;
	color: blue;
}

.admin-chart-container {
	width: 60%;
}
