.super-admin-scan-details {
	width: 50%;
	border-radius: 15px;
	padding: 1.5rem;
}
.bar-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	column-gap: 2rem;
	row-gap: 1rem;
	margin-top: 2rem;
}
.titleWithSelect {
	display: flex;
	justify-content: space-between;
	align-items: self-start;
}
.element-section-title {
	font-size: 1.2rem;
	font-weight: bold;
	text-align: left;
}
.element-section-value {
	font-size: 1.4rem;
	font-weight: bolder;
	text-align: left;
	margin-bottom: 2rem;
	color: red;
}
