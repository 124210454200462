.upload_Img_Btn {
	position: relative;
	background: #ffffff;
	color: #000;
	padding: 15px;
	margin: 10px;
	border-radius: 10px;
	width: 150px;
	height: 50px;
	font-size: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	margin: auto;
}

.tooltip {
	position: absolute;
	top: 0;
	font-size: 14px;
	background: #ffffff;
	color: #ffffff;
	padding: 5px 8px;
	border-radius: 5px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
	position: absolute;
	content: "";
	height: 8px;
	width: 8px;
	background: #ffffff;
	bottom: -3px;
	left: 50%;
	transform: translate(-50%) rotate(45deg);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.upload_Img_Btn:hover .tooltip {
	top: -55px;
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}

svg:hover span,
svg:hover .tooltip {
	text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.upload_Img_Btn:hover,
.upload_Img_Btn:hover .tooltip,
.upload_Img_Btn:hover .tooltip::before {
	background: #25a18e;
	color: #ffffff;
}
.add_productBtn {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
