/* .all-customer-page-container .ag-icon {
	display: none !important;
} */
.customFilter {
	display: none !important;
	min-width: 169px;
	max-width: 169px;
	padding: 0px !important;
}
.filter-container {
	padding: 12px 12px;
	padding-bottom: 3px;
}
.customFilterInput {
	width: 100%;
	min-height: 24px;
	min-width: 10px;
	max-width: 141px;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	border-width: 1px;
	border-radius: 3px;
	border-style: solid;
	border-color: #babfc7;
	margin: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 6px;
}
.customFilterInput:focus {
	outline: none !important;
	box-shadow: 0 0 2px 0.1rem rgb(33 150 243 / 40%);
	border-color: var(--ag-input-focus-border-color, rgba(33, 150, 243, 0.4));
}
