@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap");

* {
	margin: 0;
	font-family: "Quicksand", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.employer-sidebar-container .select-group-name-container {
	margin-right: 0.5rem !important;
}

.employer-dashboard .avatar-icon-container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	/* margin-top: 1rem; */
	/* cursor: pointer; */
}

.employer-sidebar-container .MuiDrawer-paper {
	width: inherit !important;
	background-color: inherit !important;
}

.employer-dashboard .sidebar-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 1rem;
	/* background-color: yellow; */
}

.employer-dashboard .sidebar-logo-container img {
	width: 80%;
	filter: brightness(1.5);
}

.employer-dashboard .welcome-container {
	text-align: center;
	margin-top: -80px;
	padding-top: 40px;
	padding-bottom: 20px;
}
.employer-dashboard .welcome-text {
	color: #000;
	margin-top: 30px;
	color: #e0e0e0;
	font-size: 1.3rem;
}

.employer-dashboard .toolbar {
	background-color: #eeeeee !important;
}

.employer-dashboard .dashboard-type-text {
	color: #000;
	margin-top: -20px;
	margin-bottom: 20px;
	color: #ffff;
	font-weight: bold;
	font-size: 1rem;
}

.employer-dashboard .breadcrumb-link-text {
	margin-top: -20px;
	margin-bottom: 20px;
	margin-left: 2.2rem;
	color: #bababa;
	font-weight: bold;
	font-size: 1rem;
	cursor: pointer;
}

.employer-dashboard .breadcrumb-text {
	margin-top: -20px;
	margin-bottom: 20px;
	margin-right: 0.5rem;
	color: #e0e0e0;
	font-weight: bold;
	font-size: 1rem;
	cursor: pointer;
}

.employer-dashboard .MuiBreadcrumbs-separator {
	margin-left: 4px !important;
	margin-right: 4px !important;
}

.employer-dashboard .list-item-accordion {
	color: #e0e0e0 !important;
	background-color: transparent;
	border-width: 0px !important;
	box-shadow: revert;
	letter-spacing: 0.1em;
}

.makeStyles-drawer-2::-webkit-scrollbar-track {
	background: orange; /* color of the tracking area */
}

.makeStyles-drawer-2::-webkit-scrollbar-thumb {
	background-color: blue; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
	border: 3px solid orange; /* creates padding around scroll thumb */
}
.employer-dashboard .list-item-text {
	font-size: 20px;
}

.employer-dashboard .MuiAccordionSummary-content {
	margin: 0px !important;
}

.employer-dashboard .MuiAccordionSummary-root.Mui-expanded {
	min-height: revert;
}

.employer-dashboard .list-item-accordion-summary:hover {
	background-color: #25a18e !important;
	color: #4e4e4e !important;
	font-weight: bold;
}

.employer-dashboard .list-item-container .MuiTypography-body1 {
	font-family: "Quicksand", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 0.9rem;
	letter-spacing: 0.05em;
}

.employer-dashboard .MuiListItem-button {
	color: #e0e0e0 !important;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.employer-dashboard .MuiListItem-button:hover {
	background-color: #25a18e !important;
	color: #4e4e4e !important;
	font-weight: bold;
}

.employer-dashboard .avatar-text {
	color: #558b2f;
	font-size: 1.2rem;
	font-weight: bold;
}
.appbar-header {
	text-align: center;
	font-weight: bold;
	color: #4e4e4e;
	font-size: 1.3rem;
}

.employer-dashboard .main {
	background-color: "#fafafa" !important;
}

.sec-desk .MuiBadge-badge {
	background-color: #85ce36 !important;
}

.employer-dashboard .MuiPopover-paper {
	top: 50px !important;
}
.sec-desk a {
	text-decoration: none;
	color: #4e4e4e;
}

.notification-popover {
	top: 12px !important;
}

.employer-dashboard .welcome-text {
	cursor: pointer;
}

.employer-notificationArrowWithScroll {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 15px solid #fff;
	z-index: 9999;
	position: absolute;
	transition: 2s;
	display: none;
	margin-top: 42px;
	margin-left: calc(100% - 79.5px);
}
.employer-notificationArrowNoScroll {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 15px solid #fff;
	z-index: 9999;
	position: absolute;
	transition: 2s;
	display: none;
	margin-top: 42px;
	margin-left: calc(100% - 79.5px);
}
@media only screen and (max-width: 600px) {
	.appbar-header {
		font-size: 1rem;
	}
}
