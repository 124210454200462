.dataCard {
	width: 100%;
	border: 1px solid black;
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dataCard .title {
	background-color: #003545;
	border-radius: 10px 10px 0 0;
	color: white;
	font-weight: bold;
	font-size: 1.3rem;
	padding-top: 10px;
	padding-bottom: 10px;
}
.dataCard .value {
	display: flex;
	background-color: white;
	border-radius: 0 0 10px 10px;
	flex-direction: column;
	justify-content: center;
	min-height: 5vh;
	font-size: 1rem;
}
