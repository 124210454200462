.custom-dialog-content {
	font-family: "Quicksand", sans-serif !important;
}

.custom-term-dialog-content {
	font-family: "Quicksand", sans-serif !important;
	color: #d30000 !important;
}

.custom-dialog-title {
	font-family: "Quicksand", sans-serif !important;
}

.custom-dialog-action-button-container {
	margin-bottom: 0.5rem !important;
}
