.user-approval {
	padding: 1em;
}
.user-approval .table-header-container {
	background-color: #424242;
	margin-bottom: 24px;
}

.user-approval .table-header {
	font-size: 1rem;
	color: #fff;
	font-weight: bold;
	margin-bottom: 10px;
}

.user-approval .table-item-container {
	margin-bottom: 24px;
}

.user-approval .table-item-container:hover {
	background-color: #eeeeee !important;
	cursor: pointer;
}

.user-approval .table-item {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 10px;
}

.user-approval .filter-form-control {
	min-width: 200px;
	margin-bottom: 3.5em;
}

.user-approval .filter-user-container {
	padding: 1em;
	padding-right: 2.5em;
}

.user-approval .MuiPaginationItem-page.Mui-selected {
	background-color: #25a18e;
}

.user-approval .ag-grid-cell-button {
	margin-right: 10px !important;
}

.user-approval .search-by-user-container {
	/* padding-top: 1.5em; */
}
.user-approval .pf-action-button-container {
	/* display: flex; */
	/* justify-content: flex-end; */
	text-align: end;
	margin-bottom: 1rem;
	margin-top: -1rem;
}

.user-approval .margin-10 {
	margin-bottom: 1em !important;
}
