.all-customer-page-container .ag-icon {
	display: none !important;
}

.all-customer-page-container .MuiPaginationItem-page.Mui-selected {
	background-color: #25a18e;
}

.dependent-details-container .return-btn {
	margin-top: 0.8rem !important;
	margin-bottom: 0.5rem !important;
}
