.heading {
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.images-section {
	padding: 1rem;
}
.allImages {
	margin-top: 2rem;
}
.images-section .image-heading {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 2px;
	text-align: center;
	width: 100%;
}
.image-holder {
	max-width: 40%;
	max-height: 20vh;
}
.image-holder img {
	width: 100%;
	height: 100%;
	border: 4px solid #183d3d;
	border-radius: 12px;
}
.image-holder p {
	font-weight: bold;
	color: rgb(89, 88, 88);
}
.feetImageContainer {
	display: flex;
	justify-content: space-around;
	row-gap: 1rem;
	flex-wrap: wrap;
	margin-top: 1.5rem;
}
.predictedImageContainer,
.rawImageContainer {
	display: flex;
	justify-content: space-around;
}
.qrContainer {
	background-color: #f8f8ff !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 15px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.linkQr {
	margin-left: auto;
	padding: 2rem;
	width: 100%;
}
/* .feetImage .len-label {
	transform: rotate(270deg);
} */
.leftShoeImage {
	display: flex;
	justify-content: flex-end;
}
.rightShoeImage {
	display: flex;
	justify-content: flex-start;
}
.leftShoeImage img,
.rightShoeImage img {
	border: 3px solid #003545;
}
.shoeImage img {
	width: "100%";
}
.feetImage img {
	width: 70%;
	/* padding: 10px; */
	border: 3px dashed #003545;
	margin: 12px;
}
.sideFeet img {
	width: 100%;
}
.right-image-section {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}
.flexLabel {
	display: flex;
	background-color: #003545;
	align-items: center;
}
.len-label {
	transform: rotate(-90deg);
}
