@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap");

body {
	margin: 0;
	font-family: "Quicksand", sans-serif !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	color: #4e4e4e !important;
}

:root {
	--blue: #1e90ff;
	--theme-green: #25a18e;
	--button-bg-color: #004e64;
	--button-color: #ffffff;
	--grey-bg-color: #eeeeee;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.theme-primary-color {
	color: var(--theme-green);
}

.auth-paper-container {
	padding: 2rem;
	width: 300px;
	margin-top: 2rem;
	background-color: #f5f5f5 !important;
}

.auth-text {
	font-size: 1.5rem;
	font-weight: bold;
}

.theme-form-margin {
	margin-top: 1rem;
}

.theme-section-margin {
	margin-top: 2rem;
}

.fullwidth {
	width: 100%;
}

.login {
	font-family: inherit !important;
}

.button-icon {
	/* margin-left: 0.5rem; */
	/* color: var(--button-color); */
	font-size: 1.2rem;
	/* margin-top: 0.3rem; */
}

.auth {
	height: 100vh;
	width: 100vw;
	background-image: url("./assets/login-background.jpeg");
	background-size: cover;
	background-position: center;
}

.auth-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 5rem calc((100vw - 850px) / 2);
}

.auth-img {
	width: 400px;
	height: 100px;
}

.theme-input-label {
	font-size: 1.3rem !important;
	font-weight: bold !important;
	font-family: inherit !important;
}

.auth-actions a {
	text-decoration: none;
	color: var(--button-bg-color);
	font-weight: 400;
}

.form-grid-field {
	display: flex !important;
	flex: 1 !important;
	align-items: flex-start !important;
	justify-content: flex-start !important;
	margin-left: 3rem !important;
}

.form-grid-label {
	margin-top: 10px !important;
}

.ag-grid-cell-button {
	height: 2em !important;
	color: var(--theme-green) !important;
	text-transform: none !important;
	/* border-color: #a5d6a7 !important; */
	border: 0.085rem solid var(--theme-green) !important ;
	background-color: #eafaf8 !important;
}

.grid-header {
	color: var(--theme-green);
	font-weight: bold;
	font-size: 2rem;
	text-align: left;
	margin-top: -2.3rem;
}

.app-bar {
	background-color: var(--grey-bg-color) !important;
}

.toolbar-header {
	color: #4e4e4e;
	font-weight: bold;
	font-size: 1.8rem;
}

/* ====From Nexcal Portal */
/* Plan Switch Container */
.switch-container {
	margin-top: 1rem;
}

.switch-grid-container {
	justify-content: end;
}

.veb-plan-switch-container,
.core-plan-switch-container {
	margin-top: 0.5rem !important;
	font-weight: 600;
}

.MuiSwitch-colorSecondary.Mui-checked {
	color: #359a4c !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: rgba(40, 167, 69, 0.8) !important;
}

/* Report Management Container */
.report-management-container .glb-tab-panel {
	margin-bottom: 1.5rem;
}

/* Test Portal Styles */
.test-portal-container {
	margin-right: 1rem;
	margin-top: 0.2rem;
	font-weight: bold;
}

.customer-naming-container {
	margin-top: 1rem;
	margin-left: 1rem;
	font-weight: bold;
}

.customer-naming-container .chip-container {
	color: #4cb464;
	border-color: #4cb464 !important;
	background-color: #d5e5d9;
}

/* Enrollment Approve Submit Button Styles */
.enrollment-submit-button-container {
	text-align: center;
}

.mg-top-20 {
	margin-top: 20px;
}

.mg-right-ve10 {
	margin-right: -13px;
}

/* Employment Action Button Container */
.customer-action-button-container {
	text-align: end;
	margin-top: 1rem;
	margin-bottom: -1rem;
}

/* Plan menu item */
.plan-code-menu-item {
	margin-left: 0.5rem;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	color: #28a745 !important;
	border-color: #a5d6a7 !important;
	border-radius: 100px;
	/* border: 0.085rem solid #a5d6a7 !important ; */
	/* border-style: solid; */
	/* border-width: 1px; */
	border-radius: 5px;
	font-weight: bold;
	background-color: rgba(40, 167, 69, 0.12549019607843137) !important;
}

.group-tag-indicator-right {
	margin-top: 1rem;
	padding: 0.5rem 1rem;
	width: fit-content;
	color: #4cb464 !important;
	background-color: #d5e5d9 !important;
	border-radius: 10rem;
	font-weight: 700;
}

/* Location menu item in select field */
.location-name-menu-item {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	font-weight: 450;
}

.location-number-menu-item {
	margin-left: 0.5rem;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	color: #28a745 !important;
	border-color: #a5d6a7 !important;
	/* border: 0.085rem solid #a5d6a7 !important ; */
	/* border-style: solid; */
	/* border-width: 1px; */
	border-radius: 5px;
	font-weight: bold;
	background-color: rgba(40, 167, 69, 0.12549019607843137) !important;
}

/* Custom Pagination Styling */
.custom-pagination-inner-container {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	margin-top: 30px;
}

.pagination .MuiPaginationItem-page.Mui-selected {
	background-color: #25a18e;
}

.pagination .MuiPaginationItem-page.Mui-selected:hover {
	/* background-color: #25a18e; */
	background-color: var(--theme-green);
	filter: brightness(89%);
	-webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	/* box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

/* Snackbar Styling */
.css-cpgvjg-MuiSnackbar-root {
	justify-content: center !important;
	right: 0px !important;
}

/* For Mui Disabled Fields */
.MuiInputBase-root.Mui-disabled {
	color: #939393 !important;
	cursor: pointer !important;
}

#disabled-input-text-field {
	cursor: not-allowed !important;
}

/* For Auth logo */
.logo {
	width: 250px;
	height: 100px;
}

/* This Style is to turn-off the Default Visibility Icon of Password of the Browser */
::-ms-reveal {
	display: none;
}

button {
	text-transform: none !important;
}

.action-container {
	text-align: right;
}
/* Group Assign */

.search-bar-container {
	margin-bottom: 2em;
	text-align: center;
}

.search-bar-grid-item .MuiFormLabel-root .Mui-focused {
	color: green !important;
}

.group-options-container {
	margin-top: 2em;
}

.paper-groups {
	height: 300px !important;
	width: 220px !important;
	overflow: auto !important;
	text-align: center !important;
	font-size: 1rem !important;
}

.group-text {
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1em;
}

.buttons-container {
	/* min-width: 130px !important; */
	justify-content: center !important;
	padding-top: 3em !important;
}

.buttons-container .MuiButtonBase-root {
	min-width: 130px !important;
}

.button-item {
	margin-bottom: 0.5rem !important;
	/* margin-top: 0.5rem !important; */
	width: 120px !important;
}

.MuiGrid-root .buttons-container {
	justify-content: center !important;
	padding-top: 3em !important;
}

.groups-container {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.selected-groups-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.MuiGrid-root .selected-groups-container {
	padding-left: 2em;
}

.selected-group-text {
	text-align: center;
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1em;
}

.text-align-right {
	text-align: right;
}

.glb-tab-panel {
	margin-bottom: 1.5rem !important;
}

.ag-grid-cell-button-pop-up {
	/* height: 2em !important; */
	padding: 4px;
	border-radius: 5px;
	color: #28a745 !important;
	/* border-color: #a5d6a7 !important; */
	border: 0.085rem solid #a5d6a7 !important ;
	background-color: rgba(40, 167, 69, 0.12549019607843137) !important;
}

.ag-grid-cell-button-grey {
	height: 2em !important;
	color: #404040 !important;
	border: 0.08334rem solid #828b82 !important ;
	background-color: #e0e0e0 !important;
}

.custom-dialog-title-with-icon-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.custom-dialog-actions-button-container {
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
}

.custom-dialog-action-button {
	background-color: #3a4652 !important;
	letter-spacing: 1px !important;
	font-size: 0.7rem !important;
	font-weight: bold !important;
	color: #ffff !important;
	margin-right: 10 !important;
	margin-left: 5 !important;
}

.custom-dialog-action-button-green {
	margin-right: 0.5rem !important;
	height: 2rem !important;
	/* color: #28a745 !important; */
	color: var(--theme-green) !important;
	/* border-color: #a5d6a7 !important; */
	border: 0.085rem solid #a5d6a7 !important ;
	background-color: rgba(40, 167, 69, 0.12549019607843137) !important;
	font-weight: bold !important;
}

.dark-button {
	background-color: #31363a !important;
	color: white !important;
}

.custom-dialog-action-button-red {
	margin-right: 0.5rem !important;
	height: 2rem !important;
	color: #dc3545 !important;
	border: 0.08334rem solid #df9da4 !important ;
	background-color: rgba(220, 53, 69, 0.12549019607843137) !important;
}

.custom-dialog-action-button-grey {
	margin-right: 0.5rem !important;
	height: 2rem !important;
	color: #404040 !important;
	/* border-color: #a5d6a7 !important; */
	border: 0.08334rem solid #828b82 !important;
	/* background-color: rgba(40, 167, 69, 0.12549019607843137) !important; */
	background-color: #e0e0e0 !important;
	font-weight: bold !important;
}

/* Button Label With Icon */
.custom-dialog-action-button-label-with-icon {
	margin-right: 10px !important;
	font-size: 0.9rem !important;
	font-weight: bold !important;
	letter-spacing: 0.1em !important;
}

/* Icon Style */
.custom-dialog-action-button-icon {
	font-size: 1.2rem !important;
	font-weight: bold !important;
	display: flex !important;
}

@media (max-width: 1066px) {
	.MuiGrid-root .groups-container {
		justify-content: center !important;
		padding-top: 2em !important;
	}

	.MuiGrid-root .selected-groups-container {
		justify-content: center !important;
		padding-top: 2em !important;
	}

	.MuiGrid-root .buttons-container {
		justify-content: center !important;
		padding-top: 3em !important;
	}
}

/* Styles For Form Validation Empty Fields */
.MuiFormHelperText-root {
	color: #e91e63 !important ;
	font-size: 11pt !important;
}

/* Styles For Form Validation Empty Fields of Select Fields */
.select-validation-text {
	margin-left: 14px;
	margin-right: 14px;
	margin-top: 3px;
	text-align: left;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	color: #e91e63 !important;
	font-size: 11pt !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.select-validation-text2 {
	/* margin-left: 14px; */
	margin-right: 14px;
	margin-top: 3px;
	text-align: left;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	color: #e91e63 !important;
	font-size: 11pt !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.select-validation-text3 {
	margin-left: 42px;
	margin-right: 14px;
	margin-top: 3px;
	text-align: left;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	color: #e91e63 !important;
	font-size: 11pt !important;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

/* Global button Style */

.button {
	margin: 0px 10px !important;
	text-transform: none !important;
}

.chip-container {
	text-align: center;
	margin-bottom: 1rem;
}

.chip-green {
	display: contents !important;
	color: #28a745 !important;
	border-style: none;
	font-weight: 590;
	/* background-color: rgba(40, 167, 69, 0.12549019607843137) !important; */
}

.button-green {
	margin: 0px 10px !important;
	text-transform: none !important;
	/* background-color: #44b700 !important; */
	background-color: #25a18e !important;
	color: #ffff !important;
}

.button:hover {
	filter: brightness(88%);
	-webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-green:hover {
	filter: brightness(88%);
	-webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button-black {
	margin: 0px 10px !important;
	text-transform: none !important;
	background-color: #3a4652 !important;
	color: #ffff !important;
}

.button-black:hover {
	filter: brightness(88%);
	-webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ag-theme-alpine .ag-row-hover {
	background-color: #c9f5ee !important;
}

/* Auto Approve Enrollment Button Styling */
.enrollment-action-button-container {
	text-align: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

/* Button Label With Icon */
.button-label-with-icon {
	margin-right: 10px !important;
	font-size: 0.9rem !important;
	font-weight: bold !important;
	letter-spacing: 0.1em !important;
	color: #ffff;
}

/* Icon Style */
.button-icon {
	font-size: 1.2rem !important;
	font-weight: bold !important;
	display: flex !important;
	color: #ffff;
}

.flex-center {
	display: flex;
	flex: 1;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.MuiInputBase-input {
	font-family: "Quicksand", sans-serif !important;
}

.display-flex {
	display: flex;
	flex: 1;
}

.required::after {
	content: " *" !important;
	color: #e91e63 !important;
}

.export-excel-container {
	text-align: end;
}

@media only screen and (max-width: 600px) {
	.makeStyles-content-7 {
		padding: 10px !important;
	}
}
.label-number-item {
	margin-left: 0.5rem;
	padding: 5px;
	padding-left: 0.4rem;
	padding-right: 0.4rem;
	color: #25a18e !important;
	border-color: #eafaf8 !important;
	/* border: 0.085rem solid #a5d6a7 !important ; */
	/* border-style: solid; */
	/* border-width: 1px; */
	border-radius: 5px;
	font-weight: bold;
	background-color: #eafaf8;
}
.grid-customer-details,
.report-customer-details {
	background-color: #f6fff3;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	border: 1.5px solid #004e64;
	border-radius: 15px;
	padding: 10px;
}
.grid-customer-details h3 {
	color: #25a18e;
}
.report-customer-details h3 {
	color: #25a18e;
}
.grid-customer-details p,
.report-customer-details p {
	font-weight: bold;
}

/* for making all ag grid text-allign left  */
.ag-cell-value {
	text-align: left !important;
}

.inline-flex {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
}

/* Form styles for announcement + create member */

.pf-grid-container {
	display: flex;
	flex-direction: column;
}

.pf-action-button-container {
	text-align: end;
	margin-bottom: 1rem;
}

.margin-top-action-button {
	margin-top: 2rem;
}

.pf-title {
	margin-top: 2rem;
	/* margin-bottom: 1rem; */
	font-size: 1.2rem;
	font-weight: bold;
	text-align: left;
}

.pf-paper-container {
	margin-top: 1em;
	padding: 2em 2em 0em 2em;
}

.pf-label-text {
	text-align: left;
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	margin-top: 0.7em;
	/* margin-bottom: 1em; */
}

.pf-label {
	margin-bottom: 2rem !important;
}

.check-support {
	margin-left: -1.5rem;
}

/* Announcements */
.edit-announcements .group-assign-action-button-container {
	text-align: end;
	margin-bottom: 1em;
}

.edit-announcements .title {
	margin-top: 1rem;
	/* margin-bottom: 1rem; */
	font-size: 1.2rem;
	font-weight: bold;
	text-align: left;
}

.edit-announcements .image-upload {
	display: flex;
	flex-direction: row;
}

.edit-announcements .paper-container {
	margin-top: 1em;
	padding: 2em;
}

.edit-announcements .chip-container {
	padding-left: 1rem;
}

.edit-announcements .form-label {
	margin-top: 1rem;
	/* margin-bottom: 1rem; */
}

.edit-announcements .form-label-margin-2px {
	margin-top: 2rem;
}

.edit-announcements .label-text {
	text-align: left;
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	margin-top: 0.7em;
	margin-bottom: 1em;
}

.edit-announcements .caption-text {
	text-align: left;
	font-size: 1.1rem;
	color: #424242;
	font-weight: bold;
	margin-top: 0.7em;
	margin-bottom: 1em;
}

.edit-announcements .screen-divider {
	margin-top: 2rem !important;
}

.edit-announcements .opacity-container {
	margin-top: 2rem !important;
	margin-bottom: -1.5rem;
}

.edit-announcements .subject-color {
	padding-top: 0.5em;
}

.edit-announcements .subject-color-picker-container {
	margin-left: 1rem;
}

.edit-announcements .subject-caption {
	margin-left: 1rem;
	margin-top: 2rem;
}

.edit-announcements .description-color {
	padding-top: 0.5em;
}

.edit-announcements .Description-color-picker-container {
	margin-left: 1rem;
}

.edit-announcements .description-caption {
	margin-left: 1rem;
	margin-top: 2rem;
}

.edit-announcements .subject-enabled-checkbox {
	margin-left: 0.5rem;
	margin-top: 2rem;
}

.edit-announcements .subject-enabled-text {
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	padding-left: 0.5rem;
	padding-top: 1rem;
}

.edit-announcements .description-enabled-checkbox {
	margin-left: 0.5rem;
	margin-top: 2rem;
}

.edit-announcements .description-enabled-text {
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	padding-left: 0.5rem;
	padding-top: 1rem;
}

.edit-announcements .caption-alignment {
	margin-left: 1rem;
}

.edit-announcements .alignment-options {
	margin-top: 0.3rem;
}
.edit-announcements .popper-primary-color {
	z-index: 99999;
}

/* Mapping */
.employee-mapping .flex-container {
	display: flex;
	flex-direction: row-reverse;
}

.employee-mapping .smooth-scroll-nav {
	margin-top: 2rem;
}

.employee-mapping .list {
	list-style-type: none;
	padding: 0px;
}

.employee-mapping .options-header {
	font-size: 1.2rem;
	font-weight: bold;
	letter-spacing: 0.05rem;
	padding-bottom: 0.5rem;
}

.employee-mapping .list li {
	padding: 0.5rem 0.7rem;
	font-size: 1rem;
	margin-left: -0.7rem;
	border-left: 4px solid transparent;
}

.employee-mapping .group-name {
	font-size: 1.5rem;
	font-weight: bold;
	color: #757575;
	margin: 0rem 0rem;
	text-align: center;
}

.employee-mapping .title {
	font-size: 1.2rem;
}

.employee-mapping .settings-container {
	margin-top: 1rem;
	padding: 2rem;
}

.employee-mapping .scroll-link-element {
	margin-bottom: 3rem;
}

.employee-mapping .settings-label {
	font-weight: bold;
}

.employee-mapping .smooth-scroll-nav a {
	cursor: pointer !important;
}

.employee-mapping .list li:hover {
	border-left: 4px solid #e0e0e0;
}

.employee-mapping .list .active {
	border-left: 4px solid #757575;
}

.employee-mapping .MuiFab-primary,
.employee-mapping .MuiFab-primary:hover {
	background-color: #44b700;
}

.employee-mapping .vertical-navigation {
	/* position: fixed; */
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
	padding-left: 1.5rem !important;
	/* For Border Left */
	margin-top: -1rem;
	border-left: 2px solid #e0e0e0;
	padding-top: 26px;
	height: 100%;
	/* height: 748vh; */
}

.note_input {
	width: 100%;
}
.note_paper {
	padding: 20px;
}
.notes .button-list {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 1rem;
}
.inline-flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}
.space-between {
	justify-content: space-between;
}
.circle-button {
	background-color: #a5d6a7 !important;
	width: fit-content;
	padding: 10px 15px;
	border-radius: 100%;
}
.icon {
	margin-right: 20px;
}
.green-border {
	border-radius: 20px;
	padding: 20px;
	border: 5px solid #44b700;
}

/* .employee-mapping .excel-headers {
	overflow-y: scroll;
	height: 400px;
	width: 500px;
} */

@media (max-width: 1280px) {
	/* .smooth-scroll-nav {
		display: none;
	} */
	.page-design-container {
		display: flex;
		flex-direction: column-reverse;
	}
	.vertical-navigation {
		display: flex !important;
		flex-direction: row !important;
		justify-content: flex-end !important;
	}
}
