/*  .list-item-accordion ::-webkit-scrollbar {
	width: 0.8rem;
	height: 0.8rem;
}

 .list-item-accordion::-webkit-scrollbar-track {
	background: rgba(180, 193, 180, 0.906);
	border-radius: 1rem;
	margin-block: 0.5em;
}

 .list-item-accordion::-webkit-scrollbar-thumb {
	background-color: #818b99;
	border: 3px solid transparent;
	border-radius: 9px;
	background-clip: content-box;
} */

.makeStyles-drawerPaper-6::-webkit-scrollbar {
	width: 12px; /* width of the entire scrollbar */
	height: 1rem;
}

.makeStyles-drawerPaper-6::-webkit-scrollbar-track {
	background: rgba(180, 193, 180, 0.906);
	border-radius: 1rem;
	margin-block: 0.5em;
}

.makeStyles-drawerPaper-6::-webkit-scrollbar-thumb {
	background-color: #818b99;
	border: 3px solid transparent;
	border-radius: 9px;
	background-clip: content-box;
}
