.label {
	font-weight: bold;
	font-size: 1rem;
}
.data-card-list {
	margin-top: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 12px;
	row-gap: 10px;
}
