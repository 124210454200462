.group-basic-details .detail-label {
	font-size: 0.9rem;
	color: #44b700;
	font-weight: bold;
}

.group-basic-details .header {
	font-size: 1.1rem;
	font-weight: bold;
	color: #757575;
	margin-top: 0.5rem;
}

.group-basic-details .form-field {
	margin-top: 0.5rem;
}
