.assign-groups {
	margin-top: -24px;
	height: calc(100% - 64px + 48px);
	overflow: auto !important;
}

.assign-groups .assign-groups-container {
	height: 100%;
}

.assign-groups .no-result {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100%;
	font-size: 1rem;
	font-weight: bold;
}

@media only screen and (max-width: 1200px) {
	.assign-groups-container .MuiGrid-root {
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
		margin: 10px 0 0 0;
		padding: 0;
	}
	.assign-groups-container .group-info {
		display: none;
	}
}
