.theme-button .button {
	background-color: var(--button-bg-color);
	font-family: inherit !important;
	font-weight: bold;
	color: var(--button-color);
	font-size: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.theme-button .button:hover {
	background-color: var(--button-bg-color);
	font-family: inherit !important;
	font-weight: bold;
	color: var(--button-color);
	font-size: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.theme-button .button:focus {
	background-color: inherit !important;
}
