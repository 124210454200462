.painpointDetailsDivision {
	/* background-color: rgb(234, 250, 248); */
	background-color: #f8f8ff;
	height: 100%;
	border-radius: 15px;
	padding: 1rem;
}
.painpointDetailsDivision img {
	width: 100%;
	/* border: 3px dashed #003545; */
	/* background-color: white; */
}
.painpointDetailsDivision .footDisease {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.footDisease img {
	width: 60%;
	margin-bottom: 2rem;
}
.footDisease h3 {
	color: #25a18e;
}
.knowMore button {
	background-color: #25a18e;
	color: white;
	border: none;
	border-radius: 15px;
	padding: 8px 14px;
	cursor: pointer;
	margin-top: 1rem;
}
.knowMore button:hover {
	background-color: #c9f5ee;
	color: #25a18e;
	transition-duration: 0.6s;
}

.know-more-popup {
	background-color: rgba(0, 0, 0, 0.68);
	position: absolute;
	color: white;
	font-weight: bold;
	padding: 2rem;
	border-radius: 10px;
	z-index: 99;
	left: 10%;
	top: 20%;
	font-size: 1rem;
	/* display: none; */
}

.cross-button {
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	padding: 2rem;
}

.main-para,
.second-text,
.second-para {
	margin-top: 2rem;
}

.diagnosis-text {
	width: 250px;
	text-align: right;
	font-size: 1.2rem;
	margin-right: 100px;
	font-weight: bold;
	margin-top: 20px;
}
