@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap");

* {
	margin: 0;
	font-family: "Quicksand", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.admin-dashboard .welcome-container {
	text-align: center;
	margin-top: -80px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.welcome-container .sidebar-icon-container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-bottom: 12px;
}

.admin-dashboard .welcome-text {
	color: #000;
	margin-top: 30px;
	color: #e0e0e0;
	/* color: #4e4e4e; */
	font-size: 1.3rem;
}

.admin-dashboard .toolbar {
	background-color: #eeeeee !important;
}

.admin-dashboard .sidebar-logo-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem 1rem;
	/* background-color: yellow; */
}

.admin-dashboard .sidebar-logo-container img {
	width: 80%;
	filter: brightness(1.5);
}

.admin-dashboard .dashboard-type-text {
	/* margin-top: -20px; */
	margin-bottom: 20px;
	color: #e0e0e0;
	/* color: #4e4e4e; */
	font-weight: bold;
	font-size: 1.1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .admin-dashboard .sidebar-icon-container {
	background: white;
	color: #b0a9a9;
	border-radius: 3rem;
	margin-left: 0.5rem;
	font-size: 20px;
} */

.admin-dashboard .list-item-accordion {
	color: #e0e0e0 !important;
	background-color: transparent;
	border-width: 0px !important;
	box-shadow: revert;
	letter-spacing: 0.1em;
}

.admin-dashboard .list-item-text {
	font-size: 20px;
}

.admin-dashboard .MuiAccordionSummary-content {
	margin: 0px !important;
}

.admin-dashboard .MuiAccordionSummary-root.Mui-expanded {
	min-height: revert;
}

.admin-dashboard .list-item-accordion-summary:hover {
	/* background-color: #85ce36 !important; */
	background-color: #25a18e !important;
	color: #4e4e4e !important;
	font-weight: bold;
}

.admin-dashboard .list-item-container .MuiTypography-body1 {
	font-family: "Quicksand", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 0.9rem;
	letter-spacing: 0.05em;
}

.admin-dashboard .MuiListItem-button {
	color: #e0e0e0 !important;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.admin-dashboard .MuiListItem-button:hover {
	/* background-color: #85ce36 !important; */
	background-color: #25a18e !important;
	color: #4e4e4e !important;
	font-weight: bold;
}

.admin-dashboard .avatar-text {
	/* color: #25a18e; */
	color: #558b2f;
	font-size: 1.2rem;
	font-weight: bold;
}
.appbar-header {
	text-align: center;
	font-weight: bold;
	color: #4e4e4e;
	font-size: 1.3rem;
}

.admin-dashboard .main {
	background-color: "#fafafa" !important;
}

.sec-desk .MuiBadge-badge {
	background-color: #85ce36 !important;
}

.admin-sidebar .MuiDrawer-paper {
	width: inherit !important;
	background-color: inherit !important;
}

.admin-dashboard .MuiPopover-paper {
	top: 50px !important;
}

.sec-desk a {
	text-decoration: none;
	color: #4e4e4e;
}

.notification-popover {
	top: 12px !important;
}

.admin-dashboard .welcome-text {
	cursor: pointer;
}

.notification-popover .MuiPopover-paper {
	left: unset !important;
	right: 10px !important;
}
/* .MuiPaper-root, */
/* .MuiPaper-elevation, */
/* .MuiPaper-rounded, */
/* .MuiPaper-elevation8, */
/* .MuiPopover-paper, */
/* .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
	left: 0px !important;
	margin-left: calc(100% - 380px);
} */

@media only screen and (max-width: 600px) {
	.appbar-header {
		font-size: 1rem;
	}
}
