.error-message {
	color: #cc0033;
	display: inline-block;
	font-size: 15px;
	font-weight: 500;
	margin-top: 5px;
	margin-left: 20px;
}

/* .glb-tab-label .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
	min-height: 3rem !important;
	padding: 0.5rem 1rem !important;
	font-weight: 600 !important;
} */

.glb-tab-label {
	font-family: inherit !important;
	border: none;
	min-height: 3rem !important;
	padding: 0.5rem 1rem !important;
	font-weight: 600 !important;
	align-items: center;
}

.glb-tab-icon {
	font-size: 1.6rem !important;
	padding-right: 0.5rem !important;
}

.glb-tab-panel .MuiTab-textColorInherit.Mui-selected {
	/* color: #28a745 !important; */
	color: #25a18e !important;
	/* background-color: rgba(40, 167, 69, 0.12549019607843137) !important; */
	background-color: rgb(205, 236, 232) !important;
}

.glb-tab-panel-container {
	width: calc(100vw - 300px) !important;
}

.glb-tab-grid-container {
	margin-top: 1.5rem;
}

.grid-margin {
	margin-top: 1.5rem;
}

/* .PrivateTabIndicator-colorSecondary-7 {
	background-color: #558b2f !important;
} */

/*Memver theme*/
.paper-form-container {
	margin: 0.8em 0.8em;
	padding: 0.5rem calc((100vw - 1150px) / 2);
}

.pf-grid-container {
	display: flex;
	flex-direction: column;
}

.pf-action-button-container {
	text-align: end;
	margin-bottom: 1rem;
}

.margin-top-action-button {
	margin-top: 2rem;
}

.pf-title {
	margin-top: 2rem;
	/* margin-bottom: 1rem; */
	font-size: 1.2rem;
	font-weight: bold;
	text-align: left;
}

.pf-paper-container {
	margin-top: 1em;
	padding: 2rem 2rem 0rem 2rem;
	width: 100%;
}

.pf-label-text {
	text-align: left;
	font-size: 1rem;
	color: #424242;
	font-weight: bold;
	margin-top: 0.7em;
	/* margin-bottom: 1em; */
}

.pf-label {
	margin-bottom: 2rem !important;
}

.paper-container {
	padding: 2rem;
}

.details-form-row {
	margin-bottom: 1rem;
}

.details-form-label {
	color: #757575;
	font-weight: 700;
	margin-bottom: 1rem;
}

.details-form-container {
	padding: 0rem 10rem 0rem 0rem;
}

.css-10i04qz-MuiInputBase-root-MuiFilledInput-root:before {
	border-bottom: none !important;
}

.css-1etbs4r-MuiInputBase-root-MuiFilledInput-root {
	padding: 0px !important;
}

.no-riders {
	color: #cc0033;
}
