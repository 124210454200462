.tree-view {
	border-right: 2px solid #e0e0e0;
	padding: 24px 24px 24px 0px;
	height: 100%;
}

.tree-view .breadcrum-text-container {
	margin-bottom: 1rem;
}

.tree-view .breadcrumb-link-text {
	margin-left: 1rem;
	color: #757575;
	font-weight: 590;
	font-size: 0.9rem;
	cursor: pointer;
}

.tree-view .breadcrumb-text {
	margin-bottom: 20px;
	margin-right: 0.3rem;
	color: #3a4652;
	font-weight: bold;
	font-size: 0.9rem;
	/* cursor: pointer; */
}

.tree-view .MuiBreadcrumbs-separator {
	margin-left: 3px !important;
	margin-right: 3px !important;
}

.tree-view .select-actions-container .MuiSvgIcon-root {
	height: 0.8em;
	width: 0.8em;
}

.tree-view .flex-item-start {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.actions-divider {
	margin: 10px -24px !important;
	height: 2px !important;
	background-color: #e0e0e0 !important;
}

.mr-0px {
	margin-right: 0px !important;
}

.tree-view .assign-button-label {
	margin-right: 5px !important;
	font-size: 0.8rem !important;
	font-weight: bold !important;
	letter-spacing: 0.1em !important;
	color: #ffff;
}

.tree-view .select-actions-container {
	margin-top: 1rem;
	display: flex;
	font-weight: 700;
	color: #757575;
}

.tree-view .select-actions-container .flex-item-end {
	flex: 1;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.tree-view .MuiInputBase-input {
	height: 0.4rem !important;
}

.tree-view .items {
	margin-top: 0.5rem;
}

.tree-view .items .MuiPaper-elevation1 {
	box-shadow: none !important;
}

.tree-view .items .MuiPaper-root {
	background-color: transparent !important;
}

.tree-view .items .accordion-summary .MuiSvgIcon-root {
	height: 0.8em;
	width: 0.8em;
}

.tree-view .accordion-summary-container {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.tree-view .items .MuiTypography-body1 {
	font-size: 0.9rem;
	font-family: unset !important;
	font-weight: 700 !important;
	color: #757575;
}

.tree-view .select-group-icon {
	margin-bottom: 4px;
}

.tree-view .group-name {
	font-weight: 700 !important;
	color: #757575 !important;
	font-size: 0.9rem !important;
}

.tree-view .items .MuiAccordionDetails-root {
	padding: 0px !important;
}

.tree-view .items .MuiList-padding {
	padding: 0px;
}

.tree-view .items .location-list .MuiListItem-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.tree-view .items .location-list .MuiSvgIcon-root {
	height: 0.8em;
	width: 0.8em;
}

.tree-view .accordion-summary-container .Mui-expanded {
	margin: 0px !important;
}

.tree-view .accordion-summary {
	display: inline-block;
	justify-content: center;
	align-items: center;
}

.tree-view .no-result {
	text-align: center;
}

.tree-view .list-location-text {
	cursor: pointer;
}

@media only screen and (max-width: 1200px) {
	.tree-view {
		border-right: none;

		padding: 0;
	}
}
