.connected-list-container {
	position: relative;
	display: flex;
	align-items: flex-start;
	padding-left: 20px;
}

.vertical-line {
	position: absolute;
	left: 24px;
	top: 0;
	bottom: 0;
	width: 2px;
	background-color: #acd4ff;
}

.connected-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.connected-list-item {
	display: flex;
	align-items: center;
	position: relative;
	padding: 10px 0;
}

.dot {
	width: 10px;
	height: 10px;
	background-color: #007bff;
	border-radius: 50%;
	margin-right: 10px;
	position: relative;
	z-index: 1;
}

.text {
	font-size: 16px;
	color: #333;
}
