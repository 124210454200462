.group-information {
	margin-top: 1rem;
}
.group-information .left-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: left;
	justify-content: space-around;
}

.group-information .left-container img {
	width: 100%;
}

.group-information .information {
	display: flex;
	justify-content: flex-end;
	padding-right: 1rem;
}

.special-detail {
	display: flex;
	align-items: center;
	column-gap: 10px;
	margin-bottom: 15px;
}

.group-information .divider-container .dark-divider {
	height: 2.5px;
	width: 25%;
	background-color: #3a4652;
}

.group-information .divider-container .green-divider {
	height: 2.5px;
	width: 75%;
	background-color: #44b700;
}

.group-information .details {
	margin-top: 22px;
	text-align: left;
}
