.left-foot-profile {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.left-foot-profile .sub-header {
	text-align: center;
	background-color: var(--secondary-color);
	color: white;
}

.left-foot-profile .content {
	display: flex;
	flex-direction: row;
	margin-top: 4rem;
	max-height: 800px;
	width: 100%;
}

.left-foot-profile .image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
	width: 100%;
}

.left-foot-profile .profile-img {
	width: auto;
	object-fit: contain;
}

.left-foot-profile .arch-type {
	position: absolute;
	top: 75%;
	left: 2%;
	font-size: 1vw;
	color: #007bff;
	font-weight: bold;
}

.left-foot-profile .profile-dot {
	position: absolute;
	height: 0.8vw;
	width: 0.8vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 1.6vw;
	color: white;
	cursor: pointer;
	padding: 0.9vw;
	border-radius: 50px;
	background-color: red !important;
	box-shadow: 0 0 20px 3px red;
}

.pain-point-item-selected-profile {
	background-color: red !important;
	box-shadow: 0 0 20px 3px red !important;
}

.left-foot-profile .profile-3 {
	top: 50%;
	left: 15%;
}

.left-foot-profile .profile-2 {
	top: 50%;
	left: 33%;
}

.left-foot-profile .profile-1 {
	top: 75%;
	left: 43%;
}

.left-foot-profile .profile-4 {
	top: 20%;
	left: 16%;
}

.left-foot-profile .profile-7 {
	top: 12%;
	left: 33%;
}

.left-foot-profile .profile-8 {
	top: 38%;
	left: 48%;
}

.left-foot-profile .profile-6 {
	top: 74%;
	left: 59%;
}
